.emoji {
  width: 244px;
  display: inline-block;
  border: 1px solid #afafaf;
  border-radius: 5px;
  margin: 10px;
  padding: 7px;
  text-align: left;
}

img.preview-icon {
  height: 48px;
  width: 48px;
  float: left;
}

.name {
  font-size: 1.25em;
  display: inline-block;
  padding-top: 10px;
  padding-left: 10px;
}
